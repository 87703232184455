import Axios from "axios";

const API = Axios.create({});

API.interceptors.request.use(
    (config)=> {
        const token = (
            localStorage.getItem('token') ? localStorage.getItem('token') : "-"
        );
        config.headers.Authorization ='Bearer ' + token;
        return config;
    },
    (error) => Promise.reject(error)
)

API.interceptors.response.use(
    (response)=> {
        return response;
    },
    (error)=>{
        const { status } = error.response;
        if(status === 401){
            localStorage.clear();
        }

        return Promise.reject(error);
    }
)

export default API;