import React, { useEffect, useState } from "react";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {IconButton, MenuItem, Menu as MenuMui} from "@mui/material";
import i18n from "../../../i18n";

interface TobBarComponentProps{
    color:string
}

const TobBarComponent:React.FC<TobBarComponentProps> = ({color="white"}) => {
    const [isHide, setIsHide] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleScroll = () => {
        const offset = window.scrollY;
            if (offset > 40) {
                setIsHide(true);
            } else {
                setIsHide(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return(
        // <!-- ======= Top Bar ======= -->
        <div id="topbar" className={`d-flex align-items-center fixed-top sticky ${isHide && "topbar-scrolled"}`}>
            <div className="container d-flex justify-content-center justify-content-md-between">

            <div className="contact-info d-flex align-items-center">
                <i className="bi bi-phone d-flex align-items-center"><span style={{color:color}}>+49 160 6368992</span></i>
                <i className="bi bi-mail d-flex align-items-center ms-4"><span style={{color:color}}> info@team-inna.de</span></i>
            </div>

            <div className="languages d-md-flex align-items-center">
                <ul>
                    <li>
                        <span
                            style={{cursor:'pointer'}}
                            onClick={handleClick}
                        >
                            <GTranslateIcon style={{fontSize:"28px"}} titleAccess="Translate"/>
                        </span>
                    </li>
                </ul>
                <MenuMui
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                    <MenuItem onClick={() => changeLanguage('de')}>Deutsch</MenuItem>
                </MenuMui>
            </div>
            </div>
        </div>
    )
}
export default TobBarComponent;