import React, { useState } from "react";
import BgLogin from "../../assets/inna/login-bg.mp4";
import logo from "../../assets/logo/Brandmark_rose.png"
import { Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../Redux/CommonSlice";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { SentFPwd } from "../../Const";
import { RootState } from "../../Redux/store/ReduxStore";

const ForgotPwd:React.FC = () => {

    const dispatch = useDispatch();
    const loading = useSelector((state:RootState)=>state.common.data.loading);
    const navigate = useNavigate();
        const validationSchema = Yup.object().shape({
            username: Yup.string().email().required("Email is Required"),
        })

        const { handleSubmit, handleChange, values, handleBlur, errors, touched} = useFormik({
            initialValues:{
                username: "",
            },
            validationSchema,
            enableReinitialize: true,
            onSubmit: (values)=>{
                handleFWDSubmit(values);
            }
        })

        const handleFWDSubmit = async (values:{username:string}) => {
            dispatch(setLoading(true));
            try{
                const response = await SentFPwd(values);
                if(response.status.code ===200){
                    CustomMessage(response.status.message,'success',enqueueSnackbar);
                    navigate("/login");
                }else{
                    CustomMessage(response.status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        }
    return(
        <>
        {
            loading && <Loader />
        }
            <div id="signup">
                <video autoPlay loop muted>
                    <source src={BgLogin} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="container position-absolute text-center text-lg-start form-default" 
                data-aos="zoom-in" data-aos-delay="100"
            >
                <Form onSubmit={handleSubmit}>
                    <div className="login-form">
                        {/* <!-- logo-login --> */}
                        <div className="logo-login d-flex justify-content-between">
                            <a href="#"><img src={logo} alt=""  loading="lazy"/></a>
                            <h2>Forgot password</h2>
                        </div>
                        <FormGroup className="form-input mt-5 mb-5">
                                <FormLabel htmlFor="name">User name</FormLabel>
                                <FormControl 
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    isInvalid={Boolean(touched.username && errors.username)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="User Name"
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.username && touched.username && errors.username}
                                </FormControl.Feedback>
                        </FormGroup>
                        <div className="form-input pt-30 mt-5">
                            <input type="submit" name="submit" value="Submit" />
                        </div>
                            <Link to={"/login"} style={{float:'right'}} className="registration">login</Link>
                            {/* <a href="#" className="registration"  style={{float:'right'}}>Sign Up</a> */}
                    </div>
                </Form>
            </div>
        </>
    )
}
export default ForgotPwd;