import React, { useState } from "react";
import BgLogin from "../../assets/inna/login-bg.mp4";
import logo from "../../assets/logo/Brandmark_rose.png"
import { Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OtpInputComponent from "../../Components/OTP/OtpInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserId } from "../../Redux/Reducer/AuthReducer";
import { setLoading } from "../../Redux/CommonSlice";
import { ResentOTP, activateUserOtp, createUser } from "../../Const";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { enqueueSnackbar } from 'notistack';
import { RootState } from "../../Redux/store/ReduxStore";

    const SignUp:React.FC = () => {
        const dispatch = useDispatch();
        const userId = useSelector((state:RootState)=> state.auth.userId);
        const loading = useSelector((state:RootState)=>state.common.data.loading);
        const [check,setCheck] = useState(false);
        const navigate = useNavigate();
        const [passwordType,setPasswordType] = useState("password");

        const passwordToggle = () => {
            if(passwordType === 'password') {
                setPasswordType('text');
                return;
            }
            setPasswordType('password')
        }

        const validationSchema = Yup.object().shape({
            firstname: Yup.string().required("First Name is Required"),
            username: Yup.string().email().required("Email is Required"),
            password: Yup.string()
                .min(6, 'Password must be six characters')
                .matches(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
                    'Password must contain at least one number and one special character'
                )
                .required("Password is Required"),
        })

        const { handleSubmit, handleChange, values, handleBlur, errors, touched} = useFormik({
            initialValues:{
                username: "",
                firstname:"",
                lastname:"",
                password:"",
                createdBy:1,
                roleId:2,
            },
            validationSchema,
            enableReinitialize: true,
            onSubmit: (values)=>handleCreateUser(values)
        })
        const handleCreateUser = async (values: any) => {
            dispatch(setLoading(true));
            try{
                const response = await createUser(values);
                if(response?.status?.code === 200){
                    dispatch(setUserId(response?.data?.id));
                    CustomMessage('OTP sent to your Email','success',enqueueSnackbar);
                    setCheck(true);
                }else{
                    CustomMessage(response.status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        }

        const handleOTPSubmit = async (otp: string) => {
            dispatch(setLoading(true));
            try{
                if(userId === 0 || userId === undefined) return;
                const response = await activateUserOtp({otp:otp,id:userId});
                if(response.status.code === 200){
                    CustomMessage(response.status.message,'success',enqueueSnackbar);
                    setCheck(false);
                    dispatch(setUserId(response?.data?.userId));
                    navigate("/login");
                }else{
                    CustomMessage(response.status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        };

        const handleResentOTP = async () => {
            dispatch(setLoading(true));
            const payload = {
                id:userId
            }
            try{
                if(userId === 0 || userId === undefined) return;
                const response = await ResentOTP(payload);
                if(response.status.code === 200){
                    CustomMessage(response.status.message,'success',enqueueSnackbar);
                }else{
                    CustomMessage(response.status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        };


        return(
            <>
            {
                loading && <Loader />
            }
                {
                    check ?(
                        <div style={{
                            // position:'absolute',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "100vh",
                        }}>
                            <Paper variant="elevation" elevation={4} style={{ padding: "2%",borderRadius:"5px" }}>
                                <OtpInputComponent heading="Confirm OTP" handleOTPSubmit={handleOTPSubmit} handleResentOTP={handleResentOTP}/>
                            </Paper>
                        </div>
                    )
                    :
                    (
                        <>
                            <div id="signup">
                                    <video autoPlay loop muted>
                                        <source src={BgLogin} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                            </div>
                            <div className="container position-absolute text-center text-lg-start form-default" 
                                data-aos="zoom-in" data-aos-delay="100"
                            >
                                <Form onSubmit={handleSubmit}>
                                    <div className="login-form">
                                        <div className="logo-login d-flex justify-content-between">
                                            <a href="#"><img src={logo} alt="" loading="lazy" /></a>
                                            <h2>Registration Here</h2>
                                        </div>
                                        <FormGroup className="form-input mb-3">
                                            <FormLabel htmlFor="name">First Name</FormLabel>
                                            <FormControl 
                                                type="text"
                                                name="firstname"
                                                value={values.firstname}
                                                isInvalid={Boolean(touched.firstname && errors.firstname)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="First Name"
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.firstname && touched.firstname && errors.firstname}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                        <FormGroup className="form-input mb-3">
                                            <FormLabel htmlFor="name">Last Name</FormLabel>
                                            <FormControl 
                                                type="text"
                                                name="lastname"
                                                value={values.lastname}
                                                isInvalid={Boolean(touched.lastname && errors.lastname)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Last Name"
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.lastname && touched.lastname && errors.lastname}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                        <FormGroup className="form-input mb-3">
                                            <FormLabel htmlFor="name">Email</FormLabel>
                                            <FormControl 
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                isInvalid={Boolean(touched.username && errors.username)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="User Name"
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.username && touched.username && errors.username}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                        <FormGroup className="form-input mb-3">
                                            <FormLabel htmlFor="name">Create Password</FormLabel>
                                            <div className="password-input">
                                                <FormControl 
                                                    type={passwordType}
                                                    name="password"
                                                    value={values.password}
                                                    isInvalid={Boolean(touched.password && errors.password)}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Password"
                                                />
                                                <InputGroup.Text id="basic-addon2" onClick={passwordToggle}>
                                                    {
                                                        passwordType === 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />
                                                    }
                                                </InputGroup.Text>
                                                <FormControl.Feedback type="invalid" className="mt-5">
                                                    {errors.password && touched.password && errors.password}
                                                </FormControl.Feedback>
                                            </div>
                                        </FormGroup>

                                        <div className="form-input pt-30 mt-5">
                                            <input type="submit" name="submit" value="Registration" />
                                        </div>
                                        <Link to={"/login"} style={{float:'right'}} className="registration">login</Link>
                                    </div>
                                </Form>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
    export default SignUp;