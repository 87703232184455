import React from "react";
import aboutImage from "../../../assets/inna/2212_Inna_Tulum_3417.jpg";
import EyeIcon from "../EyeIcon/EyeIcon";
import { useTranslation } from "react-i18next";
import eye from "../../../assets/logo/Brandmark_rose.png"

const AboutComponent: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
            <div className="about-img">
              <img src={aboutImage} alt="about image" style={{ objectFit: 'cover', height: "600px", width: "100%" }} loading="lazy" />
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h1 style={{ color: "#dfaaa9", fontSize: "44px", fontWeight: "800" }}>{t("about_1")}</h1>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <div style={{ display:'flex' }}>
                  <img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} />
                  <p> {t("about_2")}</p>
                </div>
              </li>
              <li>
                <div style={{ display:'flex' }}>
                  {/* <EyeIcon style={{ fontSize: 26, marginRight: '5px' }} /> */}
                  <img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} />
                  <p>{t("about_3")}
                  <br />
                  {t("about_3.1")}
                  </p>
                </div>
              </li>
              <li>
                <div style={{ fontSize: '16px',display:'flex' }}>
                  {/* <EyeIcon style={{ fontSize: 26, marginRight: '5px' }} /> */}
                  <img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} />
                  <p style={{marginTop:"15px"}}>{t("about_4")}</p>
                </div>
              </li>
              <li>
                <div style={{ fontSize: '16px',display:'flex' }}>
                  {/* <EyeIcon style={{ fontSize: 26, marginRight: '5px' }} /> */}
                  <img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} />
                  <p>{t("about_5")}
                    <br />
                    {t("about_5.1")}
                  </p>
              </div>
              </li>
              {/* <br/> */}
              <li>
                <div style={{ fontSize: '16px',display:'flex' }}>
                  {/* <EyeIcon style={{ fontSize: 26, marginRight: '5px' }} /> */}
                  <div style={{ fontSize: 26, marginRight: '5px',height:"80px", width:"80px" }}></div>
                  {/* <img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px", display:'hidden' }} /> */}
                  <p style={{marginTop:"15px"}}>Smile and shine!</p>
                </div>
              </li>
            </ul>
            <div style={{ display: 'flex', justifyContent: "center" }}>
              <a href="#book-now" className="book-a-table-btn scrollto d-none d-lg-flex">{t("book_now")}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutComponent;