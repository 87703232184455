import React from "react";
import { useTranslation } from "react-i18next";
import DataProductionImage from "../../assets/logo/dataProduction.png";
import { useNavigate } from "react-router-dom";

const Dataproduction: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <div style={{ marginTop: '10%', padding: '0 10% 0 10%' }}>
                <h1>{t("dataProduction_heading")}</h1>
                <p>{t("dataProd_p1")}</p>
                <p>{t("dataProd_p2")}</p>
                <p>As of: July 7, 2023</p>

                <a href="https://datenschutz-generator.de/" target="_blank">
                    <img src={DataProductionImage} alt="data production" />
                </a>

                <h1>{t("data_table_head")}</h1>
                <p
                    dangerouslySetInnerHTML={{ __html: t("data_table_li") }}
                />

                <h1>{t("dataPro_heading_3")}</h1>

                <p>
                    GURU & YOGI LIFESTYLE LLC<br />
                    ALI FIRAT CIGDEM SR SOLE MBR<br />
                    7901 4TH ST N STE 300<br />
                    ST PETERSBURG, FL 33702<br />
                </p>
                <p>ALI FIRAT CIGDEM</p>
                <p>E-mail address:</p>
                <p> <a href="guruandyogi@gmail.com">guruandyogi@gmail.com</a><br /></p>
                <p>Imprint:
                    <a onClick={() => navigate("/terms_condition/imprint")} style={{ color: 'blue', textDecoration: "underline", cursor: 'pointer' }}>Imprint</a></p>
                <br></br>

                <h1>{t("dataPro_relevant_head")}</h1>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_relevant_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_relevant_ul") }} />

                <p dangerouslySetInnerHTML={{ __html: t("dataPro_P_Note_on") }} />

                <h1>Overview of processing</h1>
                <p>The following overview summarizes the types of data processed and the purposes of their processing and refers to the data subjects.</p>
                <h2>Types of data processed</h2>
                <ul
                    dangerouslySetInnerHTML={{ __html: t("dataPro_type_pross_Li") }}
                />

                <h2>Special Categories of Data</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_category_li") }} />

                <h2>Categories of data subjects</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_subject_category_li") }} />

                <h2>Purposes of processing</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_purpose_process") }} />

                <h1>Safety measures</h1>
                <p>{t("dataPro_Safety_measure_p1")}</p>

                <p>{t("dataPro_Safety_measure_p2")}</p>

                <p>{t("dataPro_Safety_measure_p3")}</p>

                <h1>Transfer of personal data</h1>

                <p>{t("dataPro_transmission_p1")}</p>

                <h1>International data transfers</h1>

                <p>{t("dataPro_international_data_p1")}</p>

                <p>{t("dataPro_international_data_p2")}</p>

                <p>{t("dataPro_international_data_p3")}</p>

                <p>{t("dataPro_international_data_p4")}</p>

                <h1>Deletion of data</h1>
                <p>{t("dataPro_deletion_p1")}</p>

                <p>{t("dataPro_deletion_p2")}</p>

                <h1>Rights of data subjects</h1>
                <p>{t("dataPro_data_subject_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_ul") }} />

                <p>{t("dataPro_rights_of_data_head")}</p>
                <p>{t("dataPro_rights_of_data_p")}</p>

                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_rights_of_data_ul") }} />

                <h1>{t("dataPro_cookies_h1")}</h1>
                <p>{t("dataPro_cookies_p1")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p2") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_generalinfo_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_general_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furt_serv_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_process_cookies_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_borlabs_cookies_ul") }} />
                <h1>{t("dataPro_business_h1")}</h1>
                <p>{t("dataPro_business_p1")}</p>
                <p>{t("dataPro_business_p2")}</p>
                <p>{t("dataPro_business_p3")}</p>
                <p>{t("dataPro_business_p4")}</p>
                <p>{t("dataPro_business_p5")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_process_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_special_cate_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_sub_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_legal_basis_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_fur_p2") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_shop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_coaching_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_consulting_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_ul") }} />
                <h1>{t("dataPro_payment_h1")}</h1>
                <p>{t("dataPro_payment_p1")}</p>
                <p>{t("dataPro_payment_p2")}</p>
                <p>{t("dataPro_payment_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dataprocess_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_subject_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_purpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_further_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_american_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_apple_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_google_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_master_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_paypay_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_stripe_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_visa_ul") }} />
                <h1>{t("dataPro_provision_h1")}</h1>
                <p>{t("dataPro_provision_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_pp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_lb_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_fio_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_provision_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_collection_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_email_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_united_ul") }} />
                <h1>{t("dataPro_reg_h1")}</h1>
                <p>{t("dataPro_reg_p1")}</p>
                <p>{t("dataPro_reg_p2")}</p>
                <p>{t("dataPro_reg_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tdp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dsub_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_popro_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_lbasis_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furin_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_regis_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_userpro_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_deletion_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_noobli_p1") }} />
                <h1>{t("dataPro_blogs_h1")}</h1>
                <p>{t("dataPro_blogs_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogstypes_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogsdata_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogspurpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogslegal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furinfor_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_retrieve_ul") }} />
                <h1>{t("dataPro_contact_h1")}</h1>
                <p>{t("dataPro_contact_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contacttypes_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_affectedpersons_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactpurpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactlegals_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furtproc_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactform_ul") }} />
                <h1>{t("dataPro_video_h1")}</h1>
                <p>{t("dataPro_video_p1")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p2") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p4") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_videofur_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_zoom_ul") }} />
                <h1>{t("dataPro_audio_h1")}</h1>
                <p>{t("dataPro_audio_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_audiofur_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_spotify_ul") }} />
                <h1>{t("dataPro_news_h1")}</h1>
                <p>{t("dataPro_news_p1")}</p>
                <p>{t("dataPro_news_p2")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_double_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_del_p1") }} />
                <p>{t("dataPro_regi_p3")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_contents_p1") }} />
                <p>{t("dataPro_news_info_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_legal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_oto_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_fur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_meas_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_active_ul") }} />
                <h1>{t("dataPro_add_h1")}</h1>
                <p>{t("dataPro_add_p1")}</p>
                <p>{t("dataPro_add_p2")}</p>
                <p>{t("dataPro_add_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_legal_ul") }} />
                <h1>{t("dataPro_survey_h1")}</h1>
                <p>{t("dataPro_survey_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_surveyfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleform_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_typeform_ul") }} />
                <h1>{t("dataPro_web_h1")}</h1>
                <p>{t("dataPro_web_p1")}</p>
                <p>{t("dataPro_web_p2")}</p>
                <p>{t("dataPro_web_p3")}</p>
                <p>{t("dataPro_web_p4")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_security_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_webfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleanly_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_target_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_univer_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tag_ul") }} />
                <h1>{t("dataPro_online_h1")}</h1>
                <p>{t("dataPro_online_p1")}</p>
                <p>{t("dataPro_online_p2")}</p>
                <p>{t("dataPro_online_p3")}</p>
                <p>{t("dataPro_online_p4")}</p>
                <p>{t("dataPro_online_p5")}</p>
                <p>{t("dataPro_online_p6")}</p>
                <p>{t("dataPro_online_p7")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_security_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_legal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_oto_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_onlinefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleads_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_adsremark_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_utm_ul") }} />
                <h1>{t("dataPro_presence_h1")}</h1>
                <p>{t("dataPro_presence_p1")}</p>
                <p>{t("dataPro_presence_p2")}</p>
                <p>{t("dataPro_presence_p3")}</p>
                <p>{t("dataPro_presence_p4")}</p>
                <p>{t("dataPro_presence_p5")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_presencefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_insta_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_linkedin_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_vimeo_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_utube_ul") }} />
                <h1>{t("dataPro_plugins_h1")}</h1>
                <p>{t("dataPro_plugins_p1")}</p>
                <p>{t("dataPro_plugins_p2")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_pluginsfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_integ_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_fonts_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_youtube_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_vimeoplugin_ul") }} />
                <h1>{t("dataPro_manage_h1")}</h1>
                <p>{t("dataPro_manage_p1")}</p>
                <p>{t("dataPro_manage_p2")}</p>
                <p>{t("dataPro_manage_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_managefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_calendly_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_chatgpt_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googledocs_ul") }} />
                <h1>{t("dataPro_changes_h1")}</h1>
                <p>{t("dataPro_changes_p1")}</p>
                <p>{t("dataPro_changes_p2")}</p>
                <h1>{t("dataPro_changes_h2")}</h1>
                <p>{t("dataPro_changes_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_click_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_conv_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_personal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_profiles_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_reach_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_remark_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tracking_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_controller_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_process_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_targets_ul") }} />

            </div >
        </>
    )
}
export default Dataproduction;