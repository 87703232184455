import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../../type";

export const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  role: "",
  userId: 0
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<AuthState>) => {
      const { isAuthenticated, token, role, userId } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.role = role;
      state.userId = userId;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.role = "";
      state.userId = 0;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    }
  },
});

export const { logout, setAuthentication, setToken, setUserId } = authSlice.actions;
export const authReducer = authSlice.reducer;
export default authSlice;