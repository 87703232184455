import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo/Laughter_logo_white_optimized.png"
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderComponent: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const [isSticky, setIsSticky] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 40) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }; 
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const getActiveClass = (hash: string) => {
        return location.hash === hash ? "active" : "";
    };
    
    return(
        //   <!-- ======= Header ======= -->
    <header id="header" className={`fixed-top d-flex align-items-center ${isSticky ? "fixed-top sticky header-scrolled" : "fixed-top"}`}>
        <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
            {/* <!-- Uncomment below if you prefer to use an image logo --> */}
            <a href="#" className="logo me-auto me-lg-0">
                <img src={logo} alt="logo" className="img-fluid" 
                style={{objectFit:'contain',
                    maxHeight:"150px",
                    maxWidth:"150px"
                    //  height:"120px",
                // backgroundSize:"cover"                    
                }} 
                loading="lazy"/>
            </a>

            <nav id="navbar" 
            // className="navbar order-last order-lg-0"
            className={`navbar order-last order-lg-0 ${isMobileNavOpen && "navbar-mobile"}`}
            >
                <ul>
                    <li><a className={`nav-link scrollto ${getActiveClass("#hero")}`} href="#hero" onClick={()=>navigate("/#hero")}><h3>{t('menu_home')}</h3></a></li>
                    <li><a className={`nav-link scrollto ${getActiveClass("#about")}`} href="#about" onClick={()=>navigate("/#about")}><h3>{t('menu_about')}</h3></a></li>
                    <li><a className={`nav-link scrollto ${getActiveClass("#results")}`} href="#results" onClick={()=>navigate("/#result")}><h3>{t('menu_result')}</h3></a></li>
                    <li><a className={`nav-link scrollto ${getActiveClass("#list-module")}`} href="#list-module" onClick={()=>navigate("/#list-module")}><h3>{t('menu_module')}</h3></a></li>
                    <li><a className={`nav-link scrollto ${getActiveClass("#testimonials-ratings")}`} href="#testimonials-ratings" onClick={()=>navigate("/#testimonials-ratings")}><h3>{t('menu_testimonials')}</h3></a></li>
                </ul>
                <i
                    className={`bi bi-list mobile-nav-toggle ${isMobileNavOpen ? "bi-x" : "bi-list"}`} onClick={handleMobileNavToggle}
                ></i>
            </nav>
            {/* <!-- .navbar --> */}
            <a href="#book-now" className="book-a-table-btn scrollto d-none d-lg-flex" onClick={()=>navigate("/#book-now")}>
                <h6>{t("book_now")}</h6>
            </a>

        </div>
    {/* <!-- End Header --> */}
    </header>
    )
}

export default HeaderComponent;