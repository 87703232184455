import React, { useState } from "react";
import OTPInput, {ResendOTP} from "otp-input-react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { AuthState } from "../../type";
import { RootState } from "../../Redux/store/ReduxStore";
import { CustomMessage } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";

interface OtpInputProps{
    heading:string;
    handleOTPSubmit: (otp: string ) => void;
    handleResentOTP: () => void;
}

const OtpInputComponent:React.FC<OtpInputProps> = ({ heading, handleOTPSubmit, handleResentOTP }) => {
    const [otp,setOTP] =useState("");

    const validationSchema = Yup.object().shape({
        otp : Yup.string().max(6, "Please enter the OTP").required('OTP is Required'),
    })

    const { handleChange, handleBlur, values, errors, touched} = useFormik({
        initialValues: {
            otp : ""
        },
        validationSchema,
        onSubmit: (values)=>handleOTPDataSubmit(null),
    })

    const handleOTPDataSubmit = (e?:any) => {
        e.preventDefault();
        console.log(otp.length)
        if(otp.length == 6){
            handleOTPSubmit(otp);
        }else{
            CustomMessage('OTP is Required','error',enqueueSnackbar);
        }
    }

    const handleResent =() => {
        setOTP("");
        handleResentOTP();
    }

    return(
        <>
            <h3>{heading}</h3>
            <Form onSubmit={handleOTPDataSubmit} onChange={handleChange} onBlur={handleBlur} style={{padding:'15px'}}>
                <div className="m-5">
                    <OTPInput value={otp} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={false} name="otp"/>
                    {errors.otp && touched.otp && <div>{errors.otp}</div>}
                </div>
                <ResendOTP onResendClick={handleResent} />
                <Button variant="primary" type="submit" className="mt-5">SUBMIT</Button>
            </Form>
        </>
    )
}

export default OtpInputComponent;
