import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, Image, InputGroup, Row } from "react-bootstrap";
import Logo from "../../../assets/inna/2212_Inna_Tulum_2274.jpg";
import { useDispatch, useSelector } from "react-redux";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { setLoading } from "../../../Redux/CommonSlice";
import { RootState } from "../../../Redux/store/ReduxStore";
import { courseContentUpload, deleteProfileImage, getUserData, updateUser } from "../../../Const";
import { UserList } from "../../../type";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Divider, styled, Button as MuiButton } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { decodeBase64 } from "../../../Const/const";
import DummyProfile from "../../../assets/icon/836.jpg"
import { Cancel } from "@mui/icons-material";
import { STREAM_IMAGE_GET } from "../../../Const/APIPath";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Profile:React.FC = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state:RootState)=>state.auth.userId);
    const loading = useSelector((state:RootState)=>state.common.data.loading);

    const [passwordType,setPasswordType] = useState("password");

    const passwordToggle = () => {
        if(passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password')
    }

    const [profileData,setProfileData] = useState<UserList>({
        createdBy:0,
        firstname:"",
        id:0,
        isVerified:0,
        lastname:"",
        password:"",
        roleId:"",
        updatedBy:0,
        username:"",
        role:"",
        filePath:"",
        userProfileId:0,
    });

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("First Name is Required"),
        username: Yup.string().email().required("Email is Required"),
        password: Yup.string()
            .min(6, 'Password must be six characters')
            .matches(
                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
                'Password must contain at least one number and one special character'
            )
            .required("Password is Required"),
    })

    const { handleSubmit, handleChange, values, setValues, handleBlur, errors, touched} = useFormik({
        initialValues:{
            createdBy:0,
            firstname:"",
            id:0,
            isVerified:0,
            lastname:"",
            password:"",
            roleId:"",
            updatedBy:0,
            username:"",
            role:""
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>handleSubmitChanges(values)
    })

    const handleSubmitChanges = async (values:any) => {
        console.log(values,"values");
        dispatch(setLoading(true));
        const payload = {...values}
        try{
            payload.updatedBy = userId;
                const response = await updateUser(payload,payload?.id);
                const { data, status} = response;
                if(status.code === 200){
                    CustomMessage(status.message,'success',enqueueSnackbar);
                    getProfileData();
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    useEffect(()=>{
        getProfileData();
    },[]);
    const getProfileData = async() => {
        try{
            dispatch(setLoading(true));
            const response = await getUserData(userId);
            const {data, status} = response;
            if(status.code === 200){
                setProfileData(data);
                setValues(data as any);
            }else{
                CustomMessage(status?.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    const handleFileChange = async(e:any) => {
        const fileList = (e.target as HTMLInputElement).files;
        if (fileList && fileList.length > 0) {
            const payloadData = new FormData();
                payloadData.append('filePath',fileList[0]);
                payloadData.append('createdBy',userId?.toString());
                payloadData.append('userId',userId?.toString())
            dispatch(setLoading(true));
            try{
                const response = await courseContentUpload(payloadData,"profile");
                const { data, status } = response;
                if(status.code === 200){
                    getProfileData();
                    CustomMessage(status.message,'success',enqueueSnackbar);
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }catch(error:any){
                CustomMessage(error?.message,'error',enqueueSnackbar);
            }finally{
                dispatch(setLoading(false));
            }
        }else{
            CustomMessage("No file selected",'error',enqueueSnackbar);
        }
    }
    const removeProfileFile = async () => {
        dispatch(setLoading(true));
        try{
            const response = await deleteProfileImage(userId);
            if(response.status.code === 200){
                CustomMessage(response.status.message,'success',enqueueSnackbar);
                getProfileData();
            }else{
                CustomMessage(response.status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }

    return(
        <Container className="text-dark">
            {
                loading && <Loader />
            }
            <Row>
                <div className="col-lg-4">
                    <Card className="mb-4">
                        <Card.Body className="text-center align-items-center bg-grey">
                            <div>
                            <Image
                                src={
                                        profileData?.userProfileId != 0 ?
                                            STREAM_IMAGE_GET + profileData?.userProfileId
                                            // decodeBase64(profileData?.filePath) 
                                        : DummyProfile
                                    }
                                alt="user-logo" className="w-50 img-fluid" width={300} height={300} style={{clipPath:"circle(50% at 50% 50%)"}}
                            />
                                <span onClick={() => removeProfileFile()} style={{ color: 'red', cursor: 'pointer' }} className='mt-5'>
                                    <Cancel titleAccess='Remove' />
                                </span>
                            </div>
                            <br />
                            <MuiButton
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload Profile
                                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                            </MuiButton>
                            <Divider />
                            <h5 className="my-3 text-dark">{ `${profileData?.firstname} ${profileData?.lastname != null ? profileData?.lastname : ""}` || "Full name"}</h5>
                            <p className="mb-1 text-primary">{ profileData?.role || "Role"}</p>
                        </Card.Body>
                    </Card>
                    {/* <Card className="mb-4 mb-lg-0 p-4">
                        <Card.Body className="bg-grey">
                            <ul>
                                <li>li1</li>
                            </ul>
                        </Card.Body>
                    </Card> */}
                </div>
                <Form className="col-lg-8" onSubmit={handleSubmit}>
                    <Card className="mb-4">
                        <Card.Body className="bg-grey d-grid">
                            <Row>
                                <div className="col-sm-3">
                                    <h6 className="mb-0 text-dark">FirstName</h6>
                                </div>
                                <div className="col-sm-9">
                                    {/* <p className="mb-0 text-primary">{ profileData?.firstname || "Full Name"}</p> */}
                                    <FormGroup className="form-input mb- mb-0 text-primary">
                                        <FormControl 
                                            type="text"
                                            name="firstname"
                                            value={values.firstname}
                                            isInvalid={Boolean(touched.firstname && errors.firstname)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="First Name"
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.firstname && touched.firstname && errors.firstname}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </div>
                            </Row>
                            <hr />
                            <Row>
                                <div className="col-sm-3">
                                    <h6 className="mb-0 text-dark">LastName</h6>
                                </div>
                                <div className="col-sm-9">
                                    {/* <p className="mb-0 text-primary">{ profileData?.lastname || "Full Name"}</p> */}
                                    <FormGroup className="form-input mb-3 mb-0 text-primary">
                                            <FormControl 
                                                type="text"
                                                name="lastname"
                                                value={values.lastname}
                                                isInvalid={Boolean(touched.lastname && errors.lastname)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Last Name"
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.lastname && touched.lastname && errors.lastname}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                </div>
                            </Row>
                            <hr />
                            <Row>
                                <div className="col-sm-3">
                                    <h6 className="mb-0 text-dark">Email</h6>
                                </div>
                                <div className="col-sm-9">
                                    {/* <p className="mb-0 text-primary">{ profileData?.username || "email"}</p> */}
                                    <FormGroup className="form-input mb-3 mb-0 text-primary">
                                            <FormControl 
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                isInvalid={Boolean(touched.username && errors.username)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="User Name"
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.username && touched.username && errors.username}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                </div>
                            </Row>
                            <hr />
                            <Row>
                                <div className="col-sm-3">
                                    <h6 className="mb-0 text-dark">Password</h6>
                                </div>
                                <div className="col-sm-9 mb-0 text-primary">
                                    <FormGroup className="form-input mb-3">
                                        <div className="password-input">
                                            <FormControl 
                                                type={passwordType}
                                                name="password"
                                                value={values.password}
                                                isInvalid={Boolean(touched.password && errors.password)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Password"
                                            />
                                            <InputGroup.Text id="basic-addon2" onClick={passwordToggle}>
                                                {
                                                    passwordType === 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />
                                                }
                                            </InputGroup.Text>
                                            <FormControl.Feedback type="invalid" className="mt-5">
                                                {errors.password && touched.password && errors.password}
                                            </FormControl.Feedback>
                                        </div>
                                    </FormGroup>
                                    {/* <p className="mb-0 text-primary">{ profileData?.password || "password"}</p> */}
                                </div>
                            </Row>
                            <hr />
                        </Card.Body>
                        <Card.Footer>
                            <Col style={{display:'flex',justifyContent:"flex-end"}}>
                                <Button type="submit">Save changes</Button>
                            </Col>
                        </Card.Footer>
                    </Card>
                </Form>
            </Row>
        </Container>
    )
}

export default Profile;