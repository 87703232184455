import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BookNowImage from "../../../assets/inna/2212_Inna_Tulum_2274.jpg"
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BookNow: React.FC = () => {
  const {t} = useTranslation();
  return (
    <section id="booknow-page" className="booknow-page">
      <div className="container" data-aos="fade-up">
        {/* <div className="section-title">
          <h2>Events</h2>
          <p>Organize Your Events in our Restaurant</p>
        </div> */}
        <div className="booknow-page-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div className="swiper-wrapper">

            <div className="swiper-slide">
                <div className="row event-item">
                <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100" style={{backgroundSize:"cover"}}>
                    <img src={BookNowImage} className="img-fluid" alt="" 
                        style={{
                            objectFit:'cover',
                            height:"600px",width:"100%",
                            backgroundPosition: "top"
                        }}
                        loading='lazy'
                    />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <div style={{display:"flex",justifyContent:'center',alignItems:'center',alignContent:'center',padding:'15%'}}>
                    <div>
                      <h1 style={{fontWeight: 900,color: "#dfaaa9"}}>{`${t("hero_mainHeading")} ${t("hero_mainHeading_laugh")}`}</h1>
                      <p style={{textDecoration:'line-through'}}>&euro;222.00</p>
                      <div className="price">
                        <p>&euro;111.00</p>
                      </div>
                      <Link to="/login" target='_self'>
                        <Button variant="primary" style={{width:"80%"}}>
                            <span style={{color:"white"}}>
                              <ShoppingCartIcon /> 
                                {t("add_to_cart")} 
                                {/* Add to cart / Login */}
                            </span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            </div>
        </div>

      </div>
    </section>
  );
};

export default BookNow;
