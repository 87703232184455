// export const BASE_URL = 'http://localhost/inna_backend/api/';
export const BASE_URL = 'https://innayoga.meteoriqs.com/API/api/'; //production URL

//Register
export const REGISTER_USER = BASE_URL + 'auth/createUser';
export const VERIFY_OTP_ACTIVATE_USER = BASE_URL + 'auth/verifyOtp';
export const RESENT_OTP_ACTIVATE_USER = BASE_URL + 'auth/resendOtp';

//login
export const SIGN_IN_USER = BASE_URL + 'auth/signIn';

//Forgot pwd
export const FORGOT_PWD_MAIL = BASE_URL + 'auth/forgotPwd';

//User List
export const USER_LIST_DATA = BASE_URL + 'auth/userList';
export const GET_USET_BY_ID = BASE_URL + 'Course/retrieveUser/';
export const UPDATE_USER = BASE_URL + 'Course/updateUser/';
export const DELETE_PROFILE = BASE_URL + 'course/deleteUserProfile/';

//Dashboard
export const DASHBOARD_DATA_GET = BASE_URL + 'auth/dashboard';

//Course
export const COURSE_CREATION = BASE_URL + 'course/courseDetails';
export const COURSE_UPDATION = BASE_URL + "course/updateCourseDetails/";
export const DELETE_COURSE_COVER = BASE_URL + 'course/deleteAttachment/';
export const COURSE_LIST_DATA = BASE_URL + 'course/listCourses';
export const COURSE_BY_ID_DATA = BASE_URL + 'course/courseDetails/';

export const GET_MY_COURSE = BASE_URL + 'course/myCourses';

//Testimonial
export const CREATE_TESTIMONIAL = BASE_URL + 'course/createTestimonials';
export const UPDATE_TESTIMONIAL = BASE_URL + 'course/updateTestimonials/';
export const GET_ALL_LIST_TESTIMONIAL = BASE_URL + 'course/listOfTestimonials';
export const GET_BY_TESTIMONIAL = BASE_URL + 'course/getTestimonialsById/';
export const DELETE_TESTIMONIAL = BASE_URL + "course/deleteTestimonial/";

export const FILE_UPLOAD = BASE_URL + 'course/uploadFiles/';
export const FILE_VIDEO_UPLOAD = BASE_URL + 'course/uploadVideoChunk';

export const STREAM_VIDEO_GET = BASE_URL + 'course/streamVideo/';
export const STREAM_IMAGE_GET = BASE_URL + 'course/streamImage/';

//payment
export const COURSE_PAYMENT_TRANSACTION = BASE_URL + 'course/courseTransaction';