import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TobBarComponent from '../TobBar/TobBar';
import HeaderComponent from '../Header/Header';
import FooterComponent from '../Footer/FooterComponent';

const MainLayout: React.FC = () => {
    const location = useLocation();
    const path = ["/terms_condition/imprint", "/terms_condition/dataproduction", "/terms_condition/conditions"];
    const isCheckColor = (pathKey:string) => {
        return path.includes(pathKey) ? "black" : "white"
    }
  return (
    <React.Fragment>
      <TobBarComponent color={isCheckColor(location.pathname)} />
      <HeaderComponent />
      <div 
        // style={{ marginTop: '10%', padding: '0 10% 0 10%' }}
    >
        <Outlet />
      </div>
      <FooterComponent />
    </React.Fragment>
  );
};

export default MainLayout;
