import React from "react";
import YogaResult from "./YogaResult";
import { useTranslation } from "react-i18next";

const YogaWrapper: React.FC = () => {
    const { t, i18n} = useTranslation();
    return(
            // <!-- ======= Events Section ======= -->
    <section id="events" className="events">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>{`${t("hero_mainHeading")} ${t("hero_mainHeading_laugh")}`}</h2>
                <p>{t("result_heading")}</p>
            </div>
            <span id="results">
                <YogaResult />
            </span>
        </div>
    {/* <!-- End Events Section --> */}
    </section>
    )
}
export default YogaWrapper;