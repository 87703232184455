import React from "react";
import AboutComponent from "./About";
import YogaWrapper from "../YogaResult/YogaWrapper";
import ModuleBanner from "../ModuleBanner/ModuleBanner";
import BookNow from "../BookNow/BookNow";
import CourseDescription from "../Description/CourceDescription";

const Main:React.FC = () => {
    return(
        <main id="main">
            <AboutComponent />
            <YogaWrapper />
            <ModuleBanner />
            <span id="book-now">
                <BookNow />
            </span>
            <CourseDescription />
        </main>
    )
}
export default Main;