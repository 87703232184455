import React from "react";
import { useTranslation } from "react-i18next";

const Imprint: React.FC = () => {
    const {t} = useTranslation();
    return (
        <>
            <div style={{ marginTop: '10%', padding: '0 10% 0 10%',marginBottom: '5%', }}>
                <h4>{t("imprint_head")}</h4>
                <p>
                    GURU & YOGI LIFESTYLE LLC<br />
                    ALI FIRAT CIGDEM SR SOLE MBR<br />
                    7901 4TH ST N STE 300<br />
                    ST PETERSBURG, FL 33702<br />
                </p>
                {/* <p 
                    dangerouslySetInnerHTML={{__html: t("imprint_address")}}
                /> */}
                <br></br>
                <h4>{t("contact")}</h4>
                <p>
                    Telephone: +4915164303463<br />
                    E-Mail: <a href="mailto:guruandyogi@gmail.com">guruandyogi@gmail.com</a><br />
                </p>
                <br></br>
                <h4>{t("imprint_heading_2")}</h4>
                <p 
                    dangerouslySetInnerHTML={{__html: t("imprint_address")}}
                />
                <p>{t("imprint_heading_3")}</p>
                <br></br>

                <h4>{t("imprint_Disclaimer")}</h4>
                <p>{t("imprint_Liability_content")}</p>
                <p>
                    {t("imprint_Liability_content_para")}
                </p>
                <br></br>

                <h4>{t("imprint_Liability_links")}</h4>
                <p>
                    {t("imprint_Liability_links_para")}

                </p>
                <br></br>

                <h4>{t("imprint_copyright")}</h4>
                <p>
                    {t("copyright_1")}
                </p>
                <p>
                {t("copyright_p_1")}
                </p>
                <br></br>

                <h4>{t("Liability")}</h4>
                <p>
                    {t("Liability_1")}
                </p>
                <br></br>

                <h4>Gender</h4>
                <p>
                    {t("gender_1")}
                </p>
            </div>
        </>
    );
};

export default Imprint;
