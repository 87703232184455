import { Chip, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { TestimonialDataDefault } from "../../../Const/StateVariable";
import { decodeBase64 } from "../../../Const/const";
import { Cancel } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/CommonSlice";
import { courseContentUpload, deleteCourseCoverFile, deleteTestimonial, entryTestimonial, getByTestimonial, getTestimonialList } from "../../../Const";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../Redux/store/ReduxStore";
import { GetListTestimonial, Testimonial } from "../../../type";
import swal from 'sweetalert';
import { STREAM_IMAGE_GET } from "../../../Const/APIPath";

const TestimonialsAdmin: React.FC = () => {
    const dispatch = useDispatch();
    const data = useSelector((state: RootState) => state.auth);
    const language = useSelector((state: RootState) => state.dataReducer.language);
    const loading = useSelector((state: RootState) => state.common.data.loading);
    const [testimonialData, setTestimonialData] = useState(TestimonialDataDefault);
    const [show, setShow] = useState(true);
    const [testimonials, setTestimonials] = useState<GetListTestimonial>({ english: [], german: [] });

    useEffect(() => {
        getListofTestimonial();
    }, []);

    const getListofTestimonial = async () => {
        dispatch(setLoading(true));
        try {
            const response = await getTestimonialList();
            if (response.status.code === 200) {
                setTestimonials(response.data);
            } else {
                CustomMessage(response.status.message, 'error', enqueueSnackbar);
            }
        } catch (error: any) {
            CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const hanldleFileChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const fileList = (e.target as HTMLInputElement).files;
        if (fileList && fileList.length > 0) {
            const payloadData = new FormData();
            payloadData.append('filePath', fileList[0]);
            payloadData.append('createdBy', data.userId?.toString());
            dispatch(setLoading(true));
            try {
                const response = await courseContentUpload(payloadData, "userTestimonial");
                const { data, status } = response;
                if (status.code === 200) {
                    const updatedCourseDetails = { ...testimonialData };
                    updatedCourseDetails.userImageId = data?.id;
                    updatedCourseDetails.filePath = data?.filePath;
                    setTestimonialData(updatedCourseDetails);
                    CustomMessage(status.message, 'success', enqueueSnackbar);
                } else {
                    CustomMessage(status.message, 'error', enqueueSnackbar);
                }
            } catch (error: any) {
                CustomMessage(error?.message, 'error', enqueueSnackbar);
            } finally {
                dispatch(setLoading(false));
            }
        } else {
            CustomMessage("No file selected", 'error', enqueueSnackbar);
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, language: 'english' | 'german') => {
        const { name, value } = e.target;
        setTestimonialData(prevState => ({
            ...prevState,
            [language]: {
                ...prevState[language],
                [name]: value
            }
        }));
    };

    const removeCourseCoverFile = async (id: number) => {
        dispatch(setLoading(true));
        try {
            const response = await deleteCourseCoverFile(id);
            let { data, status } = response;
            if (status.code === 200) {
                const updatedCourse = { ...testimonialData };
                updatedCourse.filePath = "";
                updatedCourse.userImageId = 0;
                setTestimonialData(updatedCourse);
                CustomMessage(status.message, 'success', enqueueSnackbar);
            } else {
                CustomMessage(status.message, 'error', enqueueSnackbar);
            }
        } catch (error: any) {
            CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleClear = () => {
        setTestimonialData({
            filePath: "",
            testimonialId: 0,
            userImageId: 0,
            english: {
                id:0,
                comments: "",
                language: "english",
                userName: ""
            },
            german: {
                id:0,
                comments: "",
                language: "german",
                userName: ""
            }
        });
    }

    const handleSubmit = async (e: any) => {
        e?.preventDefault();
        const updatedTestimonialData = { ...testimonialData };
        if (updatedTestimonialData.userImageId === 0) {
            CustomMessage("Upload the profile", 'error', enqueueSnackbar);
            return;
        }
        dispatch(setLoading(true));
        try {
            updatedTestimonialData.testimonialId === 0 ?
                updatedTestimonialData.createdBy = data?.userId
                : updatedTestimonialData.updatedBy = data?.userId;
            const payload = {
                createdBy: updatedTestimonialData.createdBy,
                english: {
                    id: updatedTestimonialData.english.id,
                    comments: updatedTestimonialData.english.comments,
                    language: updatedTestimonialData.english.language,
                    userName: updatedTestimonialData.english.userName
                },
                german: {
                    id: updatedTestimonialData.german.id,
                    comments: updatedTestimonialData.german.comments,
                    language: updatedTestimonialData.german.language,
                    userName: updatedTestimonialData.german.userName
                },
                testimonialId: updatedTestimonialData.testimonialId,
                updatedBy: updatedTestimonialData.updatedBy,
                userImageId: updatedTestimonialData.userImageId
            }
            const response = await entryTestimonial(payload);
            if (response.status.code === 200) {
                handleClear();
                setShow(true);
                CustomMessage(response.status.message, 'success', enqueueSnackbar);
                getListofTestimonial()
            } else {
                CustomMessage(response.status.message, 'success', enqueueSnackbar);
            }
        } catch (error: any) {
            CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleEdit = async (id:number) => {
        dispatch(setLoading(true));
        try{
            const response = await getByTestimonial(id);
            if(response.status.code === 200){
                setTestimonialData(response.data);
                setShow(false);
            }else{
                CustomMessage(response.status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    } 

    const handleDelete = (id:number) => {
        swal({
            className: "red-bg",
            title: "Are you sure want to delete this record?",
            text: "you will not be able to recover",
            icon: "warning", // Use 'icon' instead of 'type'
            buttons: ["Cancel", "Delete"], // Use an array for buttons
            dangerMode: true,
        }).then(async (confirmed) => {
            if (confirmed) {
                dispatch(setLoading(true));
                try{
                    const response = await deleteTestimonial(id);
                    if(response.status.code === 200){
                        CustomMessage(response.status.message,'success',enqueueSnackbar);
                        getListofTestimonial();
                    }else{
                        CustomMessage(response.status.message,'error',enqueueSnackbar);
                    }
                }catch(error:any){
                    CustomMessage(error?.message,'error',enqueueSnackbar);
                }finally{
                    dispatch(setLoading(false));
                }
            }
        })
    }

    return (
        <Container fluid>
            {data?.role === "Admin" && (
                <Chip label={show ? "Add New" : "Back"} className="mb-4 mt-2" variant="outlined" title={show ? "Add New" : "Back"} color="primary" onClick={() => {
                    handleClear();
                    setShow(!show);
                }} />
            )}
            {loading && <Loader />}
            {show ? (
                <div id='testimonials-ratings'>
                    <section id="testimonials-ratings" className="testimonials section-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-title">
                                <h2>Testimonials</h2>
                                <p>What they're saying about us</p>
                            </div>
                            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                                {testimonials && testimonials[language]?.length > 0 ? (
                                    testimonials[language].map((testimonial: Testimonial, index: number) => (
                                        <div className="testimonial-item" key={index} style={{ marginBottom: '6px' }}>
                                            <div className='d-flex flex-column flex-md-row align-items-center'>
                                                <img
                                                    // src={decodeBase64(testimonial.filePath)}
                                                    src={STREAM_IMAGE_GET + testimonial?.userImageId}
                                                    className="testimonial-img"
                                                    alt={testimonial.userName}
                                                />
                                                <div className='text-center text-md-left ml-md-4'>
                                                    <div style={{display:"flex", justifyContent:'space-between'}}>
                                                        <h3>{testimonial.userName}</h3>
                                                        <div>
                                                            <Chip 
                                                                variant="outlined" color="warning" label="Edit" icon={<EditIcon />} 
                                                                onClick={()=>handleEdit(testimonial.testimonialId)}
                                                            />
                                                            <Chip variant="outlined" color="error" label="Delete" 
                                                                onDelete={()=>handleDelete(testimonial.testimonialId)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: testimonial.comments }} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ color: "white" }}>No data found</p>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Divider variant='fullWidth' />
                    <Col><h5>Testimonials</h5></Col>
                    <div className='content-field'>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6}>
                                {testimonialData.userImageId === 0 && (
                                    <Form.Group controlId="courseCover">
                                        <Form.Label>Upload profile</Form.Label>
                                        <Form.Control type="file" onChange={(e) => hanldleFileChange(e)} accept="image/*" />
                                    </Form.Group>
                                )}
                                {testimonialData.userImageId !== 0 && (
                                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <img 
                                            src={STREAM_IMAGE_GET + testimonialData?.userImageId}
                                            // src={decodeBase64(testimonialData.filePath)} 
                                            alt='Course cover image' style={{ height: '250px', width: '100%', objectFit: 'contain' }} />
                                        <div onClick={() => removeCourseCoverFile(testimonialData.userImageId)} style={{ color: 'red', cursor: 'pointer' }} className='mt-5'>
                                            <Cancel titleAccess='Remove' />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Divider textAlign="center"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "primary.main",
                                },
                            }}
                            variant='middle'
                        >
                            <Chip label="English" color="info" variant="outlined" />
                        </Divider>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="userName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text" name="userName" required
                                        value={testimonialData.english.userName} onChange={(e) => handleChange(e, "english")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control as="textarea"
                                        rows={5} height={4} name="comments"
                                        value={testimonialData.english.comments}
                                        onChange={(e) => handleChange(e, "english")}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Divider textAlign="center"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "primary.main",
                                },
                            }}
                            variant='middle'
                        >
                            <Chip label="German" color="info" variant="outlined" />
                        </Divider>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="userName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text" name="userName" required
                                        value={testimonialData.german.userName} onChange={(e) => handleChange(e, "german")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control as="textarea"
                                        rows={5} height={4} name="comments"
                                        value={testimonialData.german.comments}
                                        onChange={(e) => handleChange(e, "german")}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-4">
                            <Col xs={12} md={6} className="d-flex justify-content-evenly">
                                <Button variant="primary" type='submit'>Submit</Button>
                                <Button variant="secondary" type='reset' onClick={handleClear}>Clear</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Container>
    );
}

export default TestimonialsAdmin;