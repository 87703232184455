import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const FooterComponent:React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return(
        <footer id="footer">
            <div className="footer-top">
                <div className="container d-flex align-items-center">
                    <div className="row w-100 justify-content-between">
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center" style={{color:"#dfaaa9",cursor:'pointer'}} onClick={()=>navigate("/terms_condition/imprint")}>
                            {t("footer_1")}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center" style={{color:"#dfaaa9",cursor:'pointer'}} onClick={()=>navigate("/terms_condition/dataproduction")}>
                            {t("footer_2")}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center" style={{color:"#dfaaa9",cursor:'pointer'}} onClick={()=>navigate("/terms_condition/conditions")}>
                            {t("footer_3")}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    &copy; Copyright by <strong><span>inna</span></strong>. {new Date().getFullYear()}
                </div>
                <div className="credits">
                    Designed by <a href="https://meteoriqs.com/" target="_blank">Meteoriqs</a>
                </div>
                </div>
        </footer>
    )
}
export default FooterComponent;