import React, { useEffect, useState } from "react";
import TobBarComponent from "../../Components/componentTemplate/TobBar/TobBar";
import HeaderComponent from "../../Components/componentTemplate/Header/Header";
import HeroComponent from "../../Components/componentTemplate/Hero/Hero";
import Main from "../../Components/componentTemplate/Main/Main";
import YogaWrapper from "../../Components/componentTemplate/YogaResult/YogaWrapper";
import { ArrowUpward } from "@mui/icons-material";
import FooterComponent from "../../Components/componentTemplate/Footer/FooterComponent";
import ModuleBanner from "../../Components/componentTemplate/ModuleBanner/ModuleBanner";
import { Button } from "@mui/material";
import Testimonial from "../../Components/componentTemplate/Testimonial/Testimonial";

const LandingHomePage: React.FC = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handlePreloaderRemoval = () => {
            const preloader = document.getElementById('preloader');
            if (preloader) {
                // If the preloader element exists, remove it after a delay
                setTimeout(() => {
                    preloader.remove();
                }, 500); // Adjust this value to increase or decrease loading time
            }
        };

        // Call the function to handle preloader removal
        handlePreloaderRemoval();
        // No dependencies are needed here, so an empty dependency array is passed
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) { // Adjust this value to determine when the button should appear
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return(
        <div>
            {/* <TobBarComponent color="white"/>
            <HeaderComponent /> */}
            <HeroComponent />
            <Main />
            <Testimonial />
            {/* <FooterComponent /> */}
              <div id="preloader"></div>
              <span onClick={scrollToTop} className={`back-to-top d-flex align-items-center justify-content-center ${showScrollButton && "active"}`}>
                <i className="bi bi-arrow-up-short"></i>
              </span>
                {/* <a href="#" className={`back-to-top d-flex align-items-center justify-content-center ${showScrollButton && "active"}`}>
                    <i className="bi bi-arrow-up-short"></i> </a> */}
        </div>
    )
}
export default LandingHomePage;