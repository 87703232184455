import React from "react";
import { useTranslation } from "react-i18next";

const Conditions: React.FC = () => {
    const {t} = useTranslation();
    return (
        <>
            <div style={{ marginTop: '10%', padding: '0 10% 0 10%' }}>
                <h1>{t("condition_main_head")}</h1>
                <h4>{t("condition_conclusion_head3")}</h4>
                <h4>{t("condition_main_head2")}</h4>
                <p>GURU & YOGI LIFESTYLE LLC<br />ALI FIRAT CIGDEM SR SOLE MBR<br />7901 4TH ST N STE 300<br />ST PETERSBURG, FL 33702</p>
                <p>{t("condition_main_head_p1")}</p>

                <p>{t("condition_main_head_p2")}</p>
                <p>{t("condition_main_head_p3")}</p>

                <p>{t("Condition_main_head_p4")}</p>
                <br></br>

                <h4>{t("condition_conclusion_head")}</h4>
                <p>{t("condition_conclusion_p")}</p>
                <br></br>

                <h4>{t("condition_withdra_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_withdra_p")}} />
                <br></br>

                <h4>{t("condition_Cancellation_head")}</h4>
                <p>{t("condition_Cancellation_p1")}</p>
                <p>{t("condition_Cancellation_p2")}</p>
                <p>{t("condition_Cancellation_p3")}</p>
                <p>{t("condition_Cancellation_p4")}</p>
                <p>{t("condition_Cancellation_p5")}</p>
                <br></br>

                <h4>{t("condition_Price_head")}</h4>
                <p dangerouslySetInnerHTML={{__html:t("condition_Price_p")}} />
                <br></br>

                <h4>{t("condition_Delivery_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_Delivery_P")}} />
                <br></br>

                <h4>{t("condition_Payment_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_Payment_p")}} />

                <br></br>
                <h4>{t("condition_Retention_head")}</h4>
                <p>{t("condition_Retention_p")}</p>
                <br></br>

                <h4>{t("condition_Dispute_head")}</h4>
                <p>{t("condition_Dispute_p")}</p>
            </div>
        </>
    );
};

export default Conditions;
