import React from "react";
import HeroImage from "../../../assets/yoga.jpg";
import sloganImage from "../../../assets/logo/Slogan_ESB.png";
import { useTranslation } from "react-i18next";

const HeroComponent: React.FC = () => {
    const { t, i18n } = useTranslation();
    return(
        //   <!-- ======= Hero Section ======= -->
    <section id="hero" className="d-flex align-items-center">
        <div className="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="300">
            <div className="row">
                <div className="col-lg-8">
                <h2>{t("hero_heading")}</h2>
                <h1>{t("hero_mainHeading")} <span>{t("hero_mainHeading_laugh")}</span></h1>
                <h2>{t("hero_heading1")}</h2>
                {/* <h2>Because happy people make better decisions.</h2> */}

                <div className="btns">
                    <a href="#about" className="btn-menu animated fadeInUp nav-link scrollto">{t("learn_more")}</a>
                    <a href="#book-now" className="btn-book animated fadeInUp scrollto">{t("book_now")}</a>
                </div>
                </div>
                <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
                    {/* <a href="#" className="glightbox play-btn"></a> */}
                    <img src={sloganImage} style={{objectFit:'contain',height:'220px',width:"80%"}} loading="lazy"/>
                </div>
            </div>
        </div>
    </section>
    // <!-- End Hero -->
    )
}
export default HeroComponent;