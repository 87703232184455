import React, { useEffect, startTransition } from 'react';
import { Loader } from './Const/Spinner';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const [hasError, setHasError] = React.useState(false);

    useEffect(() => {
        const handleError = (event: ErrorEvent) => {
            console.error('Error caught by error boundary:', event.error);
            setHasError(true);
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    if (hasError) {
        return <h1>Something went wrong. Please try again later.</h1>;
    }

    return (
        <React.StrictMode>
            <React.Suspense fallback={
                <Loader />
                // <div>Loading...</div>
            }>
                {children}
            </React.Suspense>
        </React.StrictMode>
    );
};

export default ErrorBoundary;
