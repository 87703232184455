import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../Reducer/AuthReducer";
import { CommonReducer, commonSlice } from "../CommonSlice";
import dataSlice, { dataReducer } from "../Reducer/DataReducer";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


// Root reducer combining all your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  common: commonSlice.reducer,
    dataReducer: dataSlice.reducer
  // Add other reducers here as needed
});


// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // Optionally whitelist reducers you want to persist
  whitelist: ['auth'], // Only 'auth' reducer will be persisted
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

// Create a persistor object to persist the store
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;