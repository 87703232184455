import React, { useState } from "react";
import BgLogin from "../../assets/inna/login-bg.mp4";
import logo from "../../assets/logo/Brandmark_rose.png"
import { Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../Redux/CommonSlice";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { loginUser } from "../../Const";
import { setAuthentication, setUserId } from "../../Redux/Reducer/AuthReducer";
import { RootState } from "../../Redux/store/ReduxStore";
import { setAdminLanguage } from "../../Redux/Reducer/DataReducer";

const LogIn:React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state:RootState)=>state.common.data.loading);
    const [passwordType,setPasswordType] = useState("password");
    const currentLanguage = useSelector((state:RootState) => state.dataReducer.language); // Adjust based on your state structure
    const [language, setLanguage] = useState(currentLanguage);

        const passwordToggle = () => {
            if(passwordType === 'password') {
                setPasswordType('text');
                return;
            }
            setPasswordType('password')
        }

        const validationSchema = Yup.object().shape({
            username: Yup.string().email().required("Email is Required"),
            password: Yup.string()
                .min(6, 'Password must be six characters')
                .matches(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
                    'Password must contain at least one number and one special character'
                )
                .required("Password is Required"),
        })

        const { handleSubmit, handleChange, values, handleBlur, errors, touched} = useFormik({
            initialValues:{
                username: "",
                password:""
            },
            validationSchema,
            enableReinitialize: true,
            onSubmit: (values)=>{
                handleLogin(values);
            }
        })

        const handleLogin = async (values: { username: string; password: string }) => {
            dispatch(setLoading(true));
            try {
                const response = await loginUser(values);
                const { status, data } = response;

                if (status && status.code === 200 && data && data.id != 0 && data.id != null) {
                    // dispatch(setUserId(data.id));
                    dispatch(setAuthentication({
                        isAuthenticated:true,
                        token: "Bearer token",
                        role:data?.roleName,
                        userId:data.id
                    }))
                    CustomMessage(status.message, 'success', enqueueSnackbar);
                    navigate("/Account");
                } else {
                    CustomMessage(status.message || 'Login failed', 'error', enqueueSnackbar);
                }
            } catch (error:any) {
                CustomMessage(error?.message || 'An error occurred', 'error', enqueueSnackbar);
            } finally {
                dispatch(setLoading(false));
            }
        };

        const handleLanguageChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
            const selectedLanguage = e.target.value;
            setLanguage(selectedLanguage);
            dispatch(setAdminLanguage(selectedLanguage))
        }

    return(
        <>
        {
            loading && <Loader />
        }
            <div id="signup">
                <video autoPlay loop muted>
                    <source src={BgLogin} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="container position-absolute text-center text-lg-start form-default" 
                data-aos="zoom-in" data-aos-delay="100"
            >
                <Form onSubmit={handleSubmit}>
                    <div className="login-form">
                        {/* <!-- logo-login --> */}
                        <div className="logo-login d-flex justify-content-between">
                            <a href="#"><img src={logo} alt="" loading="lazy" /></a>
                            <h2>Login Here</h2>
                        </div>
                        <FormGroup className="form-input mb-5">
                            <FormLabel htmlFor="name">User name</FormLabel>
                            <FormControl 
                                type="text"
                                name="username"
                                value={values.username}
                                isInvalid={Boolean(touched.username && errors.username)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="User Name"
                            />
                            <FormControl.Feedback type="invalid">
                                {errors.username && touched.username && errors.username}
                            </FormControl.Feedback>
                        </FormGroup>
                        <FormGroup className="form-input mb-4">
                            <FormLabel htmlFor="name">Password</FormLabel>
                            <div className="password-input">
                                <FormControl 
                                    type={passwordType}
                                    name="password"
                                    value={values.password}
                                    isInvalid={Boolean(touched.password && errors.password)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Password"
                                />
                                <InputGroup.Text id="basic-addon2" onClick={passwordToggle}>
                                    {
                                        passwordType === 'password' ? <VisibilityOffIcon color="primary"/> : <VisibilityIcon color="info"/>
                                    }
                                </InputGroup.Text>
                                <FormControl.Feedback type="invalid" className="mt-5">
                                    {errors.password && touched.password && errors.password}
                                </FormControl.Feedback>
                            </div>
                        </FormGroup>
                        <FormGroup className="form-input mb-3">
                            <FormLabel htmlFor="name">Language</FormLabel>
                            <Form.Select aria-label="Language" required className="bg-transparent" name="language" onChange={handleLanguageChange} value={language} style={{color:'blue'}}>
                                <option>Select Lanuguage</option>
                                <option value="english" className="bg-transparent">English</option>
                                <option value="german" className="bg-transparent">German</option>
                            </Form.Select>
                        </FormGroup>
                        <div className="form-input pt-30 mt-5">
                            <input type="submit" name="submit" value="Login" />
                        </div>
                        {/* <!-- Forget Password --> */}
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            <Link to={"/signup"} className="registration">Sign Up</Link>
                            <Link to={"/forgot-password"} className="registration text-decoration-underline">Forgot password?</Link>
                        </div>
                            {/* <a href="#" className="registration"  style={{float:'right'}}>Sign Up</a> */}
                    </div>
                </Form>
            </div>
        </>
    )
}
export default LogIn;