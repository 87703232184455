import React, { Suspense, startTransition } from 'react';
import { Link, Outlet, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import './styles.css';
import MainLayout from './Components/componentTemplate/Layout/MainLayout';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/store/ReduxStore';
import LandingHomePage from './Pages/Landing/LandingHome';
import Imprint from './Pages/TermsAndCondition/Imprint';
import Dataproduction from './Pages/TermsAndCondition/Dataproductions';
import SignUp from './Pages/SignUp/SignUp';
import LogIn from './Pages/Login/Login';
import ForgotPwd from './Pages/ForgotPwd/ForgotPwd';
import AdminPanel from './Pages/Panel/PanelPage';
import CourseMain from './Pages/Panel/Course/CourseMain';
import Users from './Pages/Panel/Users/Users';
import Profile from './Pages/Panel/Profile/Profile';
import TestimonialsAdmin from './Pages/Panel/TestimonialsAdmin/TestimonialsAdmin';
import Menu from './Pages/Menu';
import Conditions from './Pages/TermsAndCondition/Conditions';

function App() {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    return (
        <Router>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path='/' element={<LandingHomePage />} />
                    <Route path='/terms_condition/imprint' element={<Imprint />} />
                    <Route path='/terms_condition/dataproduction' element={<Dataproduction />} />
                    <Route path='/terms_condition/conditions' element={<Conditions />} />
                </Route>
                <Route path='/signup' element={<SignUp />} />
                <Route path='/login' element={<LogIn />} />
                <Route path='/forgot-password' element={<ForgotPwd />} />
                {isAuthenticated && (
                    <Route path="/Account" element={<Menu />}>
                        <Route path="dashboard" element={<AdminPanel />} />
                        <Route path="course" element={<CourseMain />} />
                        <Route path="users" element={<Users />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="testimonials" element={<TestimonialsAdmin />} />
                    </Route>
                )}
            </Routes>
        </Router>
    );
}

export default App;