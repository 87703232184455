import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PlayCircleOutline } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CourseDescription: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Container fluid style={{
        backgroundColor:"#1a1814",}}>
    <Row>
        <Col md={12}>
            <h1 className='d-flex justify-content-center align-items-center' style={{color:"#dfaaa9"}}>{t("description")}</h1>
                <Divider variant='middle' color='white'/>
        </Col>
    </Row>
      <Row className="justify-content-center pt-5">
        <Col md={10}>
          {/* <h2 style={{color:"#dfaaa9"}}>{t("description_heading")}</h2> */}
          <ul style={{color: "#fff",marginLeft:"20px"}}>
            <li>{t("description_content")}</li>
            <li>{t("description_content_1")}</li>
            <li>{t("description_content_2")}</li>
            <li>{t("description_content_3")}</li>
            <li>{t("description_content_4")}</li>
            <li>{t("description_content_5")}</li>
          </ul>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <h3 style={{color:"#dfaaa9"}}>{t("description_about_heading")}</h3>
          <ul style={{color: "#fff",marginLeft:"20px"}}>
            <li>{t("description_about_content_1")}</li>
            <li>{t("description_about_content_2")}</li>
            <li>{t("description_about_content_3")}</li>
          </ul>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col md={8}>
          <Button variant="contained" color="primary">
            Enroll Now <i className='bs bs-arrow-right'></i>
          </Button>
          <Button variant="outlined" color="primary">
            Watch Trailer <PlayCircleOutline />
          </Button>
        </Col>
      </Row> */}
    </Container>
  );
};

export default CourseDescription;
