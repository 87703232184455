import { Badge, Box, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import EyeIcon from "../EyeIcon/EyeIcon";
import { useTranslation } from "react-i18next";
import eye from "../../../assets/logo/Brandmark_rose.png"

const StyledDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
//   color: theme.palette.text.secondary,
  color:'whitesmoke'
}));

const YogaResult:React.FC = () => {
    const { t, i18n } = useTranslation();
    return(
        <Grid container spacing={2} data-aos="fade-up" data-aos-delay="100">
            <Grid item xs={12} sm={6} md={6}>
                <StyledDiv style={{display:"flex",justifyContent:"center", flexDirection:'column'}}>
                        {/* <div><EyeIcon style={{ fontSize: 50 }} /></div> */}
                        <div><img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} /></div>
                    <div className="section-title"><p style={{fontSize:"24px",color:'white'}}><span>{t("result_1")}</span></p></div>
                    <div>{t("result_1.1")}</div>
                </StyledDiv>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <StyledDiv style={{display:"flex",justifyContent:"center", flexDirection:'column'}}>
                    {/* <div><Badge badgeContent={2} color="success" /></div> */}
                    {/* <div><EyeIcon style={{ fontSize: 50 }} /></div> */}
                    <div><img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} /></div>
                    <div className="section-title"><p style={{fontSize:"24px",color:'white'}}><span>{t("result_2")}</span></p></div>
                    <div>{t("result_2.1")}</div>
                </StyledDiv>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <StyledDiv style={{display:"flex",justifyContent:"center", flexDirection:'column'}}>
                    {/* <div><Badge badgeContent={3} color="success" /></div> */}
                    {/* <div><EyeIcon style={{ fontSize: 50 }} /></div> */}
                    <div><img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} /></div>
                    <div className="section-title"><p style={{fontSize:"24px",color:'white'}}><span>{t("result_3")}</span></p></div>
                    <div>{t("result_3.1")}</div>
                </StyledDiv>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <StyledDiv style={{display:"flex",justifyContent:"center", flexDirection:'column'}}>
                    {/* <div><Badge badgeContent={4} color="success" /></div> */}
                    {/* <div><EyeIcon style={{ fontSize: 50 }} /></div> */}
                    <div><img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} /></div>
                    <div className="section-title"><p style={{fontSize:"24px",color:'white'}}><span>{t("result_4")}</span></p></div>
                    <div>{t("result_4.1")}</div>
                </StyledDiv>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <StyledDiv style={{display:"flex",justifyContent:"center", flexDirection:'column'}}>
                    {/* <div><Badge badgeContent={5} color="success" /></div> */}
                    {/* <div><EyeIcon style={{ fontSize: 50 }} /></div> */}
                    <div><img src={eye} style={{ fontSize: 26, marginRight: '5px',height:"80px" }} /></div>
                    <div className="section-title"><p style={{fontSize:"24px",color:'white'}}><span>{t("result_5")}</span></p></div>
                    <div>{t("result_5.1")}</div>
                </StyledDiv>
            </Grid>
        </Grid>
    )
}

export default YogaResult;