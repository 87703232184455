import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { AccountCircle, Cancel, CheckCircle, Person, ShoppingCart } from "@mui/icons-material";
import "./Panel.css"; // Importing custom CSS for styling
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store/ReduxStore";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { setLoading } from "../../Redux/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { dashboardData } from "../../Const";
import { dashboardDataDefault } from "../../Const/StateVariable";

const AdminPanel: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state:RootState)=>state.common.data.loading);

  const [adminDashboardData,setAdminDashboardData] = useState(dashboardDataDefault);
  
  useEffect(()=>{
    getDahsboardData();
  },[]);

  const getDahsboardData = async () => {
    dispatch(setLoading(true));
    try{
      const response = await dashboardData();
      const { data, status } = response;
      if(status.code === 200){
        setAdminDashboardData(data);
        console.log(response);
      }else{
        CustomMessage(status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  return (
    <>
      {
        loading && <Loader />
      }
      <div className="admin-panel-container">
        <Grid container spacing={3}>
          {/* Active Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#64DD17" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <AccountCircle className="admin-panel-icon" />
                Active Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.activeUsers}</Typography>
            </Paper>
          </Grid>

          {/* Inactive Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#FF1744" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <Cancel className="admin-panel-icon" />
                Inactive Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.inActiveUsers}</Typography>
            </Paper>
          </Grid>

          {/* Verified Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#2979FF" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <CheckCircle className="admin-panel-icon" />
                Verified Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.verifiedUsers}</Typography>
            </Paper>
          </Grid>

          {/* Unverified Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#FF6D00" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <Cancel className="admin-panel-icon" />
                Unverified Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.unVerifiedUsers}</Typography>
            </Paper>
          </Grid>

          {/* Total Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#9C27B0" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <Person className="admin-panel-icon" />
                Total Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.totalUsers}</Typography>
            </Paper>
          </Grid>

          {/* Purchased Users */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={3} className="admin-panel-card" style={{ backgroundColor: "#00BFA5" }}>
              <Typography variant="h6" gutterBottom color={"white"} className="d-flex justify-content-between">
              <ShoppingCart className="admin-panel-icon" />
                Course Purchased Users
              </Typography>
              <Typography variant="h4">{adminDashboardData?.coursePurchasedUser}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AdminPanel;
