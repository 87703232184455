import React from "react";
import { useTranslation } from "react-i18next";

const ListOfModule:React.FC = () => {
    const {t} = useTranslation();
    return(
        // <!-- ======= Testimonials Section ======= -->
    <section id="testimonials" className="testimonials section-bg">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
            <h2>{t("module_heading")}</h2>
            <p>IN " {`${t("hero_mainHeading")} ${t("hero_mainHeading_laugh")}`} "</p>
            </div>

            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                <div className="testimonial-item" style={{marginBottom:'6px'}}>
                    <p style={{color:"white"}}>
                        <h3 style={{color:"#dfaaa9"}}>{t("module_1")}</h3><br/>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t("module_1.1")}
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                </div>
                {/* <!-- End testimonial item --> */}

                <div className="testimonial-item" style={{marginBottom:'6px'}}>
                    <p style={{color:"white"}}>
                        <h3 style={{color:"#dfaaa9"}}>{t("module_2")}</h3><br/>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {t("module_2.1")}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                </div>
                {/* <!-- End testimonial item --> */}

                <div className="testimonial-item" style={{marginBottom:'6px'}}>
                    <p style={{color:'white'}}>
                        <h3 style={{color:"#dfaaa9"}}>{t("module_3")}</h3><br/>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t("module_3.1")}
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                </div>
                    {/* <!-- End testimonial item --> */}
            </div>
            <div className="d-flex justify-content-center mt-4">
                <a href="#book-now" className="book-a-table-btn scrollto">{t("book_now")}</a>
            </div>
        </div>
    {/* <!-- End Testimonials Section --> */}
    </section>
    )
}

export default ListOfModule;