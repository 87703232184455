import { CourseContent, CourseDefault, LanguageCourseContent, TestimonialDefault, UserList, dashboardDefault } from "../type";

export const userData: UserList = {
    id:0,
    lastname:'',
    password:'',
    roleId:'',
    username:'',
    firstname:'',
    createdBy:0,
    updatedBy:0,
    isVerified:0,
    filePath:"",
    userProfileId:0,
}

export const courseData: CourseDefault ={
    content: {
        english:[] as CourseContent[],
        german:[] as CourseContent[],
    } as LanguageCourseContent,
    courseCoverPictureId:0,
    coverFilePath:'',
    fileType:'',
    courseName:'',
    courseId:0,
    createdBy:0,
    updatedBy:0
}

export const dashboardDataDefault:dashboardDefault ={
    activeUsers:0,
    coursePurchasedUser:0,
    inActiveUsers:0,
    totalUsers:0,
    unVerifiedUsers:0,
    verifiedUsers:0
}

export const TestimonialDataDefault: TestimonialDefault = {
    filePath:"",
    testimonialId:0,
    userImageId:0,
    english:{
        id:0,
        comments:"",
        language:"english",
        userName:""
    },
    german:{
        id:0,
        comments:"",
        language:"german",
        userName:""
    },
    createdBy:0,
    updatedBy:0
}