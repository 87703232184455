import React, { SetStateAction, useRef, useState } from "react";
import { CourseDefault } from "../../../type";
import { List, ListItem, ListItemText, Container, Paper, Typography, Box, Chip, IconButton, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/CommonSlice";
import { RootState } from "../../../Redux/store/ReduxStore";
import { getStreamVideoURL } from "../../../Const";
import { decodeBase64 } from "../../../Const/const";
import { STREAM_IMAGE_GET, STREAM_VIDEO_GET } from "../../../Const/APIPath";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import MenuClose from '@mui/icons-material/MenuOpen';

const Sidebar = styled(Paper)(({ theme }) => ({
  backgroundColor: grey[200],
  padding: theme.spacing(1),
  boxShadow: theme.shadows[3],
  maxHeight: '100%',
  overflowY: 'auto',
  [theme.breakpoints.up('md')]: {
    position: 'static',
    transform: 'translateX(0)',
    width: '25%',
    maxWidth: 360,
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200,
    width: '100%',
    height: 'auto',
    transform: 'translateX(-100%)',
    transition: 'transform 0.3s ease-out',
    '&.open': {
      transform: 'translateX(0)',
    },
  },
}));

const Content = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
    // width: '75%',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100%',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: blue[50],
  },
  '&.Mui-selected': {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

interface MyGetCourseProps {
  courseData: CourseDefault;
  setShowContent: React.Dispatch<SetStateAction<boolean>>;
}

interface SidebarContentProps {
  courseData: CourseDefault;
  setActiveItem: React.Dispatch<SetStateAction<number>>;
  activeItem: number;
  language: string;
  open: boolean;
  toggleSidebar: () => void;
}

const SidebarContent: React.FC<SidebarContentProps> = ({open, toggleSidebar, courseData, setActiveItem, activeItem, language }) => {
  const theme = useTheme();
  return (
    <Drawer
      // variant={useMediaQuery(theme.breakpoints.up('md')) ? "persistent" : "temporary"}
      open={open}
      onClose={toggleSidebar} 
      sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              // width: useMediaQuery(theme.breakpoints.up('md')) ? 360 : 200,
              width:360,
              // height:'200px',
              marginTop: useMediaQuery(theme.breakpoints.up('md')) ? '10%' : "35%",
          },
      }}
    >
      <div onClick={toggleSidebar} style={{cursor:'pointer',display:'flex',justifyContent:"center",alignItems:'center'}}>
        <MenuClose titleAccess="Close" />
      </div>
      <List>
        {courseData?.content[language === 'english' ? 'english' : 'german']?.map(item => (
          <ListItemStyled 
            key={item?.contentId} 
            selected={item?.contentId === activeItem}
            onClick={() => {
              setActiveItem(item?.contentId);
              toggleSidebar();
            }}
          >
            <ListItemText primary={`${item.contentId} - ${item?.contentName}`} />
          </ListItemStyled>
        ))}
      </List>
    </Drawer>
  );
};

const VideoContent: React.FC<{ contentFileId: number; courseId: number; contentId: number; posterURL: string; }> = ({
  contentFileId,
  courseId,
  contentId,
  posterURL
}) => {
  const loading = useSelector((state: RootState) => state.common.data.loading);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      {loading && <Loader />} {/* Assuming Loader component is defined */}
      <video
        ref={videoRef}
        poster={posterURL}
        width="100%"
        height="auto"
        controls
        src={STREAM_VIDEO_GET + contentFileId}
        controlsList="nodownload"
        style={{ maxWidth: '100%' }}
      />
    </>
  );
};

const MyGetCourse: React.FC<MyGetCourseProps> = ({ courseData, setShowContent }) => {
  const [activeItem, setActiveItem] = useState<number>(1);
  const language = useSelector((state: RootState) => state.dataReducer.language);
  const theme = useTheme();
  const loading = useSelector((state: RootState) => state.common.data.loading);
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initially closed on small screens

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
    {
      loading && <Loader />
    }
      <Chip className="mb-5" title="Back" variant="outlined" color="info" onClick={() => setShowContent(true)} label={"Back"} />
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <SidebarContent open={sidebarOpen} toggleSidebar={toggleSidebar} courseData={courseData} setActiveItem={setActiveItem} activeItem={activeItem} language={language} />
          <div style={{ flex: 1 }}>
            <div onClick={toggleSidebar} style={{cursor:'pointer'}}>
              <MenuIcon titleAccess="Open"/>
            </div>
            {
              courseData && courseData.content && courseData.content[language === 'english' ? 'english' : 'german']?.length > 0 && (
                courseData.content[language === 'english' ? 'english' : 'german']
                  .filter(val => val.contentId === activeItem)
                  .map(val => (
                    <Content key={val.contentId}>
                      <Typography variant="h4" gutterBottom>
                        {val.contentName}
                      </Typography>
                      <div className="mb-3">
                        <VideoContent contentFileId={val.contentFileId} courseId={courseData.courseId}  contentId={val.contentId} 
                          // posterURL={decodeBase64(val.posterFilePath)} 
                          posterURL={STREAM_IMAGE_GET + val.posterPictureId}
                        />
                      </div>
                      <Typography variant="body2">
                        <p dangerouslySetInnerHTML={{ __html: val.description }} />
                      </Typography>
                    </Content>
                  ))
              )
            }
          </div>
        </Box>
      </Container>
    </>
  );
};

export default MyGetCourse;
