import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const initialState = {
    language: "english"
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setAdminLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setAdminLanguage } = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
export default dataSlice;