import { Paper } from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import bgImage from "../../../assets/inna/2212_Inna_Tulum_2829.jpg"
import bgImage from "../../../assets/inna/2212_Inna_Tulum_2977.jpg"
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import InsightsIcon from '@mui/icons-material/Insights';
import DiamondIcon from '@mui/icons-material/Diamond';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ListOfModule from './ListOfModule';
import { useTranslation } from 'react-i18next';

const ModuleBanner: React.FC = () => {
    const { t, i18n} = useTranslation();
    return (
        <>
        <section id="modules" className="d-flex align-items-center"
            style={{
                backgroundImage:`url(${bgImage})`,
                backgroundSize:"cover",
                height:"110vh",
                position:'relative',
                width:"100%",
                padding:0,
                objectFit:'contain',
                backgroundRepeat:'no-repeat'
            }}
        >
            <div className="container position-absolute text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
                <Row className="row">
                    <Col lg={8} className="d-none d-lg-block"></Col>
                    <Col className="col-lg-4">
                        <Paper variant='elevation' sx={{borderRadius:"20px"}}>
                            <div style={{padding:"25px"}}>
                                <h2 style={{ color: "black",fontSize:"22px",}}>{`${t("hero_mainHeading")} ${t("hero_mainHeading_laugh")}`}</h2>
                                <ul className="module-list">
                                    <li><span><ViewModuleIcon sx={{fontSize:'40px'}}/></span> {t("module_popup_1")}</li>
                                    <li><span><InsightsIcon sx={{fontSize:'40px'}}/></span> {t("module_popup_2")}</li>
                                    <li><span><DiamondIcon sx={{fontSize:'40px'}}/></span> {t("module_popup_3")}</li>
                                </ul>
                                <div className="btns d-flex justify-content-center">
                                    <a href="#book-now" className="btn-book animated fadeInUp scrollto">{t("book_now")}</a>
                                </div>
                            </div>
                        </Paper>
                    </Col>
                </Row>
            </div>
        </section>
        <span id="list-module">
            <ListOfModule />
        </span>
        </>
    );
};

export default ModuleBanner;