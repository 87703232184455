import React, { useEffect, useState } from 'react';
// import './testimonial.css';
import test1 from '../../../assets/inna/2212_Inna_Tulum_1813.jpg';
import test2 from '../../../assets/inna/2212_Inna_Tulum_1813.jpg';
import test3 from '../../../assets/inna/2212_Inna_Tulum_1813.jpg';
import test4 from '../../../assets/inna/2212_Inna_Tulum_1813.jpg';
import test5 from '../../../assets/inna/2212_Inna_Tulum_1813.jpg';
import CustomTestimonial from '../TestimonialCustom/CustomTestimonial';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { GetListTestimonial } from '../../../type';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../Redux/CommonSlice';
import { getTestimonialList } from '../../../Const';
import { CustomMessage } from '../../../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { decodeBase64 } from '../../../Const/const';
import { STREAM_IMAGE_GET } from '../../../Const/APIPath';
import { Box, Grid, Paper, Typography } from '@mui/material';

// const testimonials = [
//   {
//     img: test1,
//     name: 'Saul Goodman',
//     title: 'Ceo & Founder',
//     text: 'It was such a nice experience to do laughing Yoga for three full weeks. First it felt a bit strange, but from Session to Session it was more and more fun, i got more comfortable with the Tasks and in the end i did it also together with my Family - SO MUCH FUN! I can highly recommend the course for People who want to let Go Stress, who want to try out laughing Yoga with Professional guidance - in fact i can recommend it to everyone, it was Just soooo cool and i will definitely do it again and keep on going with the laughing Yoga exercises 😍✨',
//   },
//   {
//     img: test2,
//     name: 'Sara Wilsson',
//     title: 'Designer',
//     text: "Inna is not just the number one Laughter Yoga Queen for me, but a person who has changed my life significantly. She is always a step ahead of the world, which is why I just need to embrace whatever magical things Inna brings into my life. Laughter Yoga has been a part of her life for many years, and at first, I always found it a bit strange.<br/>Thanks to the short theoretical part of the course, I now completely understand why Laughter Yoga makes so much sense.<br />Happy people make better decisions, and when we laugh, we are happier.<br/>Thank you, Inna, for being you and for having the courage to bring something as unusual as Laughter Yoga into our lives.<br/>I love you<br/>Hoho Hahaha",
//   },
//   {
//     img: test3,
//     name: 'Jena Karlis',
//     title: 'Store Owner',
//     text: "I first truly experienced Inna through the Laughter Yoga workshop.<br/>I didn't know what I was getting myself into and wouldn't have done it voluntarily. However, I believe that the things meant for me will find their way to me, so I just participated... and I understood.<br/>Laughter Yoga makes so much sense. We can hardly vibrate at a higher frequency.<br/>My favorite exercise is the one where we measure our size. It is so liberating to make yourself really big and loud, instead of always making yourself small to avoid standing out.<br/>Thank you, Inna, for bringing us all a little closer to our true greatness. 🙏",
//   },
//   {
//     img: test4,
//     name: 'Matt Brandon',
//     title: 'Freelancer',
//     text: "As a B2B & B2C coach and entrepreneur, I have experienced many techniques to boost productivity and energize the body, but none have impressed me as much as laughter yoga. The combination of intentional laughter and deep breathing creates a unique atmosphere that fosters both individual creativity and collective synergy.<br/>Laughter yoga is a powerful tool that breaks down barriers, builds trust, and improves communication skills. In my coaching sessions, I have seen how it not only reduces stress but also significantly enhances resilience and motivation within teams.<br/>It is remarkable how such a simple concept can have such a profound impact. For any company that values a healthy and productive work environment, laughter yoga is an indispensable addition.",
//   },
//   {
//     img: test5,
//     name: 'John Larson',
//     title: 'Entrepreneur',
//     text: "Recently, I had the opportunity to try something completely new. Something that required me to step out of my comfort zone, and I initially thought it was pretty crazy to do it.<br/>But when I tried the first laughter yoga video, something changed within me and my body. I felt like a little kid; my demeanor and energy leveled up. I had such joy in laughing, and it lasted for hours afterward. Day by day, I felt better, happier, more energized, and more balanced.<br/>Quick question for you: Do you know what your laugh looks like? I don't mean the smile you put on for a photo, but when you really laugh out loud. No? For the first time, I watched myself laugh in the mirror and was amazed and surprised. I didn't know I had such a beautiful laugh. When do you ever see yourself laughing like that?<br/>If you haven't tried it, give it a go. Commit to 21 days of laughter yoga and see how much you change from the inside out. Even today, I still do a few laughter yoga sessions now and then, and the best part is, my little brother practically demands it. He loves it.<br/>I approach challenging, bad, and annoying situations much more relaxed, calm, and with more humor in everyday life.<br/>Think about it: How often do you laugh in a day? How often does a child laugh? How do you feel when you laugh? Believe me, these 10 minutes a day will change your mood and your life.<br/>I can wholeheartedly recommend Inna and her laughter yoga. She presents it with so much passion, charm, humor, and love that it's suitable for everyone in the family.",
//   },
// ];

const Testimonial: React.FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<number | null>(null);
  const [testimonials, setTestimonials] = useState<GetListTestimonial>({ english: [], german: [] });

  useEffect(()=>{
    getListofTestimonial();
  },[])

  const getListofTestimonial = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getTestimonialList();
      if (response.status.code === 200) {
        setTestimonials(response.data);
      } else {
        CustomMessage(response.status.message, 'error', enqueueSnackbar);
      }
    } catch (error: any) {
      CustomMessage(error?.message, 'error', enqueueSnackbar);
    } finally {
      dispatch(setLoading(false));
    }
  }

  const toggleReadMore = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };
  const truncateText = (text: string, length: number) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + ' etc....';
  };
   return (
    <section id="testimonials-ratings" className="testimonials section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("testimonials")}</h2>
          <p>{t("testimonials_heading")}</p>
        </div>
        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          {testimonials &&
            testimonials[i18n.language === "en" ? 'english' : 'german'] &&
            testimonials[i18n.language === "en" ? 'english' : 'german'].length > 0 &&
            testimonials[i18n.language === "en" ? 'english' : 'german'].map((testimonial, index) => (
              <Paper key={index} className="testimonial-item" elevation={3} sx={{ marginBottom: '20px', padding: '20px', borderRadius: '20px' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={2}>
                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                      <img
                        src={STREAM_IMAGE_GET + testimonial?.userImageId}
                        className="testimonial-img"
                        alt={testimonial.userName}
                        // style={{ width: '100%',
                        //   maxWidth: '250px',
                        //   maxHeight:'230px',
                        //   borderRadius: '10%'
                        // }}
                        loading='lazy'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                      <h3>{testimonial.userName}</h3>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: expanded === index ? testimonial.comments : truncateText(testimonial.comments, 220)
                        }}
                      />
                      {testimonial?.comments?.length > 220 && (
                        <Typography
                          component="span"
                          sx={{ color: '#007bff', cursor: 'pointer' }}
                          onClick={() => toggleReadMore(index)}
                        >
                          {expanded === index ? 'Show less' : 'Read more'}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
