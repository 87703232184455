import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/CommonSlice";
import "./CourseList.css"
import * as React from 'react';
import { CourseDefault, courseList } from "../../../type";
import { getCourseById, getListOfCourse, getMyCourse } from "../../../Const";
import { enqueueSnackbar } from "notistack";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { RootState } from "../../../Redux/store/ReduxStore";
import { courseData } from "../../../Const/StateVariable";
import MyGetCourse from "./MyGetCourse";
import { decodeBase64 } from "../../../Const/const";
import dayjs from "dayjs";
import { Card, CardContent, CardMedia, Container, Grid, Typography, CardActions, Button } from '@mui/material';
import { styled } from '@mui/system';
import { STREAM_IMAGE_GET } from "../../../Const/APIPath";

interface MyCourseProps{
  setCourseDetails:React.Dispatch<React.SetStateAction<CourseDefault>>;
  setShowContent:React.Dispatch<React.SetStateAction<boolean>>;
}

const Div = styled('div')({
  flexGrow: 1,
  padding: '20px',
});

const StyledCard = styled(Card)({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  },
  padding:'15px'
});

const Media = styled(CardMedia)({
  height: 140,
});

const Footer = styled(CardActions)({
  textAlign: 'center',
  padding: '10px 0',
});

const MyCourse:React.FC<MyCourseProps> = ({setCourseDetails,setShowContent}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state:RootState)=>state.common.data.loading);
  const user = useSelector((state:RootState)=>state.auth);
   const [courseList,setCourseList] = React.useState<courseList[]>([]);
   const [showlist,setShowList] = React.useState(true);

  React.useEffect(()=>{
      if(courseList.length === 0){
          getCourseList();
      }
  },[courseList])

  const getCourseList = async() => {
      dispatch(setLoading(true));
      try{
          const response = await getMyCourse(user?.userId,user?.role); 
          const {data,status} = response;
          if(status.code === 200 && data?.length>0){
              setCourseList(data);
          }else{
              CustomMessage(status?.message,'error',enqueueSnackbar);
          }
      }catch(error:any){
          CustomMessage(error?.message,'error',enqueueSnackbar);
      }finally{
          dispatch(setLoading(false));
      }
  }

  const handleGetCourse = async (courseId:number) =>{
    dispatch(setLoading(true));
    try{
        const response =await getCourseById(courseId);
        if(response.status.code ===200){
            setCourseDetails(response.data);
            setShowList(false);
            setShowContent(false);
        }else{
            CustomMessage(response.status.message,'error',enqueueSnackbar);
        }
    }catch(error:any){
        CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
        dispatch(setLoading(false));
    }
  }

  return (
    <div className='container-fluid'>
      {
        loading && <Loader />
      }
      <Div>
      {loading && <Loader />}
      <Grid container spacing={3}>
        {courseList &&
          courseList.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3} onClick={() => handleGetCourse(item?.courseId)} style={{cursor:'pointer'}}>
              <StyledCard>
                <Media
                  // image={decodeBase64(item?.courseCoverPicture)}
                  image={STREAM_IMAGE_GET + item?.courseCoverPictureId}
                  title="Course Cover"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.courseName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <i className="far fa-clock"></i>{' '}
                    {item?.createdTime && dayjs(item?.createdTime).format('MMMM D, YYYY')}
                  </Typography>
                </CardContent>
                <Footer>
                  <Typography variant="body2" color="textSecondary">
                    Last update: {item?.updatedTime && dayjs(item?.updatedTime).format('MMMM D, YYYY HH:MM')}
                  </Typography>
                </Footer>
              </StyledCard>
            </Grid>
          ))}
      </Grid>
    </Div>
    </div>
  );
}

export default MyCourse;