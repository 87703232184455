import { Button, Chip, Grid } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
 
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef, ITooltipParams } from "ag-grid-community";
import { Form, FormControl, FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/CommonSlice";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { UserList } from "../../../type";
import { createUser, getUserData, getUserList, updateUser } from "../../../Const";
import { RootState } from "../../../Redux/store/ReduxStore";
import { userData } from "../../../Const/StateVariable";

const Users: React.FC = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state:RootState)=>state.common.data.loading);
    const userId = useSelector((state:RootState)=>state.auth.userId);
    const [show,setShow] = useState(false);
    const [userList,setUserList] = useState<UserList[]>([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridApiRef = useRef<any>(null);
    const [passwordType,setPasswordType] = useState("password");

    useEffect(()=>{
        if(userList.length === 0){
            getListOfUsers();
        }
    },[userList]);

    const getListOfUsers = async () => {
        dispatch(setLoading(true));
        try{
            const response = await getUserList();
            const {data, status} = response;
            if(status.code === 200 && data?.length > 0){
                setUserList(data);
            }else{
                CustomMessage(status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }        
    }

    const passwordToggle = () => {
        if(passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password')
    }



    const toolTipValueGetter = (params: ITooltipParams) =>params.value == null || params.value === '' ? '- Missing -' : params.value;
    const columnDefs: ColDef[] = [
        {
            headerName:"Id",
            field:"id",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "id",
            flex:0.2,
        },
        {
            headerName:"First Name",
            field:"firstname",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "firstname",
            flex:2,
        },
        {
            headerName:"Last Name",
            field:"lastname",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "lastname",
            flex:2,
        },
        {
            headerName:"Email",
            field:"username",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "username",
            flex:3,
        },
        {
            headerName:"Role",
            field:"roleName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "roleName",
            flex:2,
        },
        {
            headerName:"Password",
            field:"password",
            filter: "agSetColumnFilter",
            sortable: true,
            editable:false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "password",
            flex:2,
        },
        {
            headerName:"Actions",
            field:"actions",
            filter: "agSetColumnFilter",
            sortable: false,
            editable:false,
            cellRenderer:(params:any)=>(
                <div>
                    <span
                    style={{ cursor: 'pointer', color: 'rgba(255, 138, 0, 1)' }}
                    onClick={() => handleGetUser(params?.data?.id)}
                    // hidden={params.row.mealSuggestionId !=0}
                    >
                        <EditIcon />
                    </span>
                </div>
            )
        }
    ]

        const validationSchema = Yup.object().shape({
            firstname: Yup.string().required("First Name is Required"),
            username: Yup.string().email().required("Email is Required"),
            password: Yup.string()
                .min(6, 'Password must be six characters')
                .matches(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/,
                    'Password must contain at least one number and one special character'
                )
                .required("Password is Required"),
        })

        const { handleSubmit, handleChange, values, setValues, handleBlur, errors, touched} = useFormik({
            initialValues:
            {
                id:0,
                createdBy:0,
                updatedBy:0,
                roleId:"",
                username: "",
                firstname:"",
                lastname:"",
                password:"",
                isVerified:0,
                filePath:"",
                userProfileId:0,
            },
            validationSchema,
            enableReinitialize: true,
            onSubmit: (values)=>handlePostUser(values),
        })

    const handlePostUser = async (values:UserList) => {
        dispatch(setLoading(true));
        const payload = {...values}
        try{
            if(payload.id === 0){
                payload.createdBy = userId;
                const response = await createUser(payload);
                const { data, status} = response;
                if(status.code === 200){
                    CustomMessage(status.message,'success',enqueueSnackbar);
                    getListOfUsers();
                    handleClear();
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }else{
                payload.updatedBy = userId;
                const response = await updateUser(payload,payload?.id);
                const { data, status} = response;
                if(status.code === 200){
                    CustomMessage(status.message,'success',enqueueSnackbar);
                    getListOfUsers();
                    handleClear();
                }else{
                    CustomMessage(status.message,'error',enqueueSnackbar);
                }
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    
    const handleGetUser = async (userId:number) => {
        dispatch(setLoading(true));
        try{
            const response = await getUserData(userId);
            const { data, status} = response;
            if(status.code === 200){
                setShow(true);
                setValues(data)
            }else{
                CustomMessage(status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    const onBtnExport = useCallback(() => {
        const fileName = "users_list.csv"; // Set your desired file name here
        gridApiRef.current!.api.exportDataAsCsv({ fileName },);
        // gridApiRef.current!.api.exportDataAsExcel();
    }, []);

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.api.sizeColumnsToFit();
        }
    }, []);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            minWidth: 200,
            filter: true,
        };
    }, []);
    const getRowId = useMemo(() => {
        return (params : any) => params?.data?.id;
    }, []);
    const handleShow = () => {
        setShow(true)
    }
    const handleClear = () => {
        setValues({
            id:0,
            firstname:"",
            lastname:"",
            password:"",
            username:"",
            createdBy:0,
            roleId:"",
            updatedBy:0,
            isVerified:0,
            filePath:"",
            userProfileId:0,
        })
        setShow(false);
    }

    
    return(
        <>
        {
            loading && <Loader />
        }
        <Grid container spacing={2} justifyContent="center" className="p-3">
            <Grid item xs={12}>
            {
            !show?
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Chip
                        style={{ margin: "2px" }}
                        label="Add User"
                        color='warning'
                        onClick={handleShow}
                        icon={<AddIcon />}
                        variant="outlined"
                    />
                </div>
            :
                <div className="d-flex justify-content-center align-items-center">
                    <Form onSubmit={handleSubmit}>
                        <div className="login-form p-5">
                            <FormGroup className="form-input mb-3">
                                <FormLabel htmlFor="name">First Name</FormLabel>
                                <FormControl 
                                    type="text"
                                    name="firstname"
                                    value={values.firstname}
                                    isInvalid={Boolean(touched.firstname && errors.firstname)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="First Name"
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.firstname && touched.firstname && errors.firstname}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup className="form-input mb-3">
                                <FormLabel htmlFor="name">Last Name</FormLabel>
                                <FormControl 
                                    type="text"
                                    name="lastname"
                                    value={values.lastname}
                                    isInvalid={Boolean(touched.lastname && errors.lastname)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Last Name"
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.lastname && touched.lastname && errors.lastname}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup className="form-input mb-3">
                                <FormLabel htmlFor="name">Role</FormLabel>
                                <Form.Select aria-label="Role" required className="bg-transparent" name="roleId" onChange={handleChange} value={values.roleId}>
                                    <option>Select Role</option>
                                    <option value="1" className="bg-transparent">Admin</option>
                                    <option value="2" className="bg-transparent">User</option>
                                </Form.Select>
                            </FormGroup>
                            <FormGroup className="form-input mb-3">
                                <FormLabel htmlFor="name">Email</FormLabel>
                                <FormControl 
                                    type="text"
                                    name="username"
                                    value={values.username}
                                    isInvalid={Boolean(touched.username && errors.username)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="User Name"
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.username && touched.username && errors.username}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup className="form-input mb-3">
                                <FormLabel htmlFor="name">Create Password</FormLabel>
                                <div className="password-input">
                                    <FormControl 
                                        type={passwordType}
                                        name="password"
                                        value={values.password}
                                        isInvalid={Boolean(touched.password && errors.password)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Password"
                                    />
                                    <InputGroup.Text id="basic-addon2" onClick={passwordToggle}>
                                        {
                                            passwordType === 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />
                                        }
                                    </InputGroup.Text>
                                    <FormControl.Feedback type="invalid">
                                        {errors.password && touched.password && errors.password}
                                    </FormControl.Feedback>
                                </div>
                            </FormGroup>

                            <div className="form-input pt-30 d-flex justify-content-between">
                                <input type="submit" name="submit" value="Submit" />
                                <Button variant="contained" color="inherit" onClick={handleClear} sx={{borderRadius:'5px',marginLeft:'2px'}}>Cancel</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            }
            </Grid>
            <Grid item xs={12}>
                <div style={{ margin: '10px 0' }}>
                    <Button
                        onClick={onBtnExport} 
                        style={{ marginBottom: '5px', fontWeight: 'bold' }} 
                        // icon={<SaveIcon />}
                        variant="outlined"
                        // customBackgroundColor="rgba(255, 138, 0, 1)"
                    >Export</Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={containerStyle}>
                        <div  style={gridStyle}>
                            <AgGridReact
                                className="ag-theme-alpine"
                                defaultColDef={defaultColDef}
                                rowData={userList}
                                columnDefs={columnDefs}
                                onGridReady={(params) => (gridApiRef.current = params)}
                                // defaultColDef={{ flex: 1, minWidth:100 }}
                                domLayout="autoHeight"
                                // editType="fullRow"
                                // onCellValueChanged={handleCellValueChanged}
                                pagination={true}
                                paginationPageSize={20}
                                // cacheBlockSize={10}
                                getRowId={getRowId}
                                suppressMenuHide={true}
                            />  
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
        </>
    )
}

export default Users;